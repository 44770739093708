<template lang="pug">
  .create-account(
    :class="{ loading }"
  )
    .container-fluid
      BAlert.create-account-alert(
        :show="isAccountCreatedAlertShown"
        variant="success"
        dismissible
      ) {{ $t("account_management.create_account.account_created") }}
      .row.create-account-heading
        .col-sm-6
          span {{ formTitle }}
      .row.create-account-form
        .col-md-12.col-lg-10.col-xl-8.col-xxl-6.p-l-25.p-r-2
          .row.create-account-form-row(
            :data-cy="$t('account_management.create_account.fields.last_name')"
          )
            .col-sm-6
              FormFieldLabel(
                :title="$t('account_management.create_account.fields.last_name')"
                required
              )
            .col-sm-6
              .create-account-form-field
                BFormInput(
                  type="text"
                  name="last-name"
                  v-model="account.last_name"
                  :placeholder="$t('account_management.create_account.placeholders.last_name')"
                  :state="getFieldState('last_name')"
                )
                BFormInvalidFeedback(v-if="isInvalidAccountLastName") {{ $t("validations.at_least_1_letter") }}
                BFormInvalidFeedback(
                  v-if="errors.last_name"
                  v-for="error in errors.last_name"
                  :key="error"
                ) {{ error }}
          .row.create-account-form-row(
            :data-cy="$t('account_management.create_account.fields.first_name')"
          )
            .col-sm-6
              FormFieldLabel(
                :title="$t('account_management.create_account.fields.first_name')"
                required
              )
            .col-sm-6
              .create-account-form-field
                BFormInput(
                  type="text"
                  name="first-name"
                  v-model="account.first_name"
                  :placeholder="$t('account_management.create_account.placeholders.first_name')"
                  :state="getFieldState('first_name')"
                  aria-describedby="input-first-name"
                )
                BFormInvalidFeedback(v-if="isInvalidAccountFirstName") {{ $t("validations.at_least_1_letter") }}
                BFormInvalidFeedback(
                  v-if="errors.first_name"
                  v-for="error in errors.first_name"
                  :key="error"
                ) {{ error }}
          .row.create-account-form-row(
            :data-cy="$t('account_management.create_account.fields.account_id')"
          )
            .col-sm-6
              FormFieldLabel(
                :title="$t('account_management.create_account.fields.account_id')"
                required
              )
            .col-sm-6
              .create-account-form-field
                .d-flex.align-items-center.w-100
                  BFormInput(
                    type="text"
                    name="account-id"
                    v-model="account.account_id"
                    :placeholder="$t('account_management.create_account.placeholders.account_id')"
                    :state="getFieldState('account_id')"
                    :disabled="!isNewUser"
                    @blur="checkAccountIdAlreadyExists"
                    @input="cleanAccountIdErrors"
                    @keyup="handleAccountIdKeyup"
                  )
                  FaIcon.m-l-10(
                    v-if="accountIdAlreadyExistsLoading"
                    icon="spinner"
                    spin
                  )
                BFormInvalidFeedback(
                  v-if="isInvalidAccountAccountId"
                  :state="false"
                ) {{ $t("validations.at_least_1_letter") }}
                BFormInvalidFeedback(
                  v-if="errors.account_id"
                  v-for="error in errors.account_id"
                  :key="error"
                  :state="false"
                ) {{ error }}
          .row.create-account-form-row(
            :data-cy="$t('account_management.create_account.fields.password')"
          )
            .col-sm-6
              FormFieldLabel(
                :title="$t('account_management.create_account.fields.password')"
                :required="isNewUser"
              )
            .col-sm-6
              .create-account-form-field
                AppInputPassword(
                  v-model="account.password"
                  :placeholder="$t('account_management.create_account.placeholders.password')"
                  :invalid="isInvalidAccountPassword"
                )
          .row.create-account-form-row(
            :data-cy="$t('account_management.create_account.fields.password_confirmation')"
          )
            .col-sm-6
              FormFieldLabel(
                :title="$t('account_management.create_account.fields.password_confirmation')"
                :required="isNewUser"
              )
            .col-sm-6
              .create-account-form-field
                AppInputPassword(
                  v-model="account.password_confirmation"
                  name="password-confirmation"
                  :placeholder="$t('account_management.create_account.placeholders.password_confirmation')"
                  :invalid="isInvalidAccountPasswordConfirmation"
                )
                .password-errors(v-if="isInvalidAccountPassword || isInvalidAccountPasswordConfirmation")
                  BFormInvalidFeedback(
                    :state="false"
                  )
                  li(v-if="passwordRequiredError") {{ passwordRequiredError }}
                  li(v-if="minLengthPasswordError") {{ minLengthPasswordError }}
                  li(v-if="equalConfirmationPasswordError") {{ equalConfirmationPasswordError }}
          .row.create-account-form-row(
            :data-cy="$t('account_management.create_account.fields.email')"
          )
            .col-sm-6
              FormFieldLabel(:title="$t('account_management.create_account.fields.email')")
            .col-sm-6
              .create-account-form-field
                BFormInput(
                  type="email"
                  name="email"
                  v-model="account.email"
                  :placeholder="$t('account_management.create_account.placeholders.email')"
                  :state="getFieldState('email')"
                )
                BFormInvalidFeedback(v-if="$v.account.email.$error") {{ $t("validations.invalid_email") }}
          .row.create-account-form-row(
            :data-cy="$t('account_management.create_account.fields.phone_number')"
          )
            .col-sm-6
              FormFieldLabel(:title="$t('account_management.create_account.fields.phone_number')")
            .col-sm-6
              .create-account-form-field
                BFormInput(
                  type="tel"
                  name="phone-number"
                  v-model="account.phone_number"
                  :placeholder="$t('account_management.create_account.placeholders.phone_number')"
                  :state="getFieldState('phone_number')"
                )
                BFormInvalidFeedback(v-if="$v.account.phone_number.$error") {{ $t("validations.invalid_phone_number") }}
          .row.create-account-form-row(
            v-if="!account.owner"
            :data-cy="$t('account_management.create_account.fields.role')"
          )
            .col-sm-6
              FormFieldLabel(
                :title="$t('account_management.create_account.fields.role')"
                required
              )
            .col-sm-6
              .create-account-form-field-roles
                AppDropdown.user-role(
                  close-on-select
                  :items="roles"
                  :value="currentRole"
                  :class="{ invalid: $v.account.role_id.$error }"
                  @select="selectRole"
                )
                BFormInvalidFeedback.m-l-6.d-block(v-if="$v.account.role_id.$error") {{ $t("validations.invalid_user_role") }}

      .row.create-account-items(
        v-if="!account.owner && !this.isOrganizationWithSharedInventory"
      )
        .col-sm-12
          .create-account-items-title {{ $t("account_management.create_account.shop_permission.title") }}
        .col-md-12.col-lg-7.col-xxl-6
          .create-account-items-list
            .create-account-items-list-title
              h6.nowrap {{ $t("account_management.create_account.shop_permission.shop_list", { number: shops.length }) }}
              h6.tip {{ $t("account_management.create_account.shop_permission.tip") }}
            AppDropdown.create-account-items-list-select(
              batch-select
              allow-empty
              searchable
              multiple
              checkbox
              only-body
              :autofocus="false"
              :items="shops"
              :value="selectedShops"
              @select="selectShop"
            )
        .col-md-12.col-lg-5.col-xxl-6
          .create-account-items-selected
            .create-account-items-selected-title
              span {{ $t("account_management.create_account.shop_permission.selected_shops") }}
              BFormInvalidFeedback.d-block(
                v-if="$v.account.allowed_shop_ids.$error"
              ) {{ $t("validations.at_least_1_item") }}

            .create-account-items-selected-list(v-show="selectedShops.length")
              .create-account-items-selected-list-item(
                v-for="shop in selectedShops"
                :key="shop.id"
              )
                span {{ shop.name }}

      .row.create-account-items(
        v-if="!account.owner && this.isOrganizationWithSharedInventory"
      )
        .col-sm-12
          .create-account-items-title {{ $t("account_management.create_account.inventory_group_permission.title") }}
        .col-md-12.col-lg-7.col-xxl-6
          .create-account-items-list
            .create-account-items-list-title
              h6.nowrap {{ $t("account_management.create_account.inventory_group_permission.inventory_group_list", { number: inventoryGroups.length }) }}
              h6.tip {{ $t("account_management.create_account.inventory_group_permission.tip") }}
            AppDropdown.create-account-items-list-select(
              batch-select
              allow-empty
              searchable
              multiple
              checkbox
              only-body
              :autofocus="false"
              :items="inventoryGroups"
              :value="selectedInventoryGroups"
              @select="selectInventoryGroups"
            )
        .col-md-12.col-lg-5.col-xxl-6
          .create-account-items-selected
            .create-account-items-selected-title
              span {{ $t("account_management.create_account.inventory_group_permission.selected_inventory_groups") }}
              BFormInvalidFeedback.d-block(
                v-if="$v.account.allowed_inventory_group_ids.$error"
              ) {{ $t("validations.at_least_1_item") }}

            .create-account-items-selected-list(v-show="selectedInventoryGroups.length")
              .create-account-items-selected-list-item(
                v-for="inventoryGroup in selectedInventoryGroups"
                :key="inventoryGroup.id"
              )
                span {{ inventoryGroup.name }}

      .row.create-account-items(
        v-if="!account.owner"
      )
        .col-sm-12
          .create-account-items-title {{ $t("account_management.create_account.car_class_permission.title") }}
        .col-md-12.col-lg-7.col-xxl-6
          .create-account-items-list
            .create-account-items-list-title
              h6.nowrap {{ $t("account_management.create_account.car_class_permission.car_class_list", { number: carClasses.length }) }}
              h6.tip {{ $t("account_management.create_account.car_class_permission.tip") }}
            AppDropdown.create-account-items-list-select(
              batch-select
              allow-empty
              searchable
              multiple
              checkbox
              only-body
              :autofocus="false"
              :items="carClasses"
              :value="selectedCarClasses"
              @select="selectCarClass"
            )
        .col-md-12.col-lg-5.col-xxl-6
          .create-account-items-selected
            .create-account-items-selected-title
              span {{ $t("account_management.create_account.car_class_permission.selected_car_classes") }}
              BFormInvalidFeedback.d-block(
                v-if="$v.account.allowed_car_class_ids.$error"
              ) {{ $t("validations.at_least_1_item") }}

            .create-account-items-selected-list(v-show="selectedCarClasses.length")
              .create-account-items-selected-list-item(
                v-for="carClass in selectedCarClasses"
                :key="carClass.id"
              )
                span {{ carClass.name }}

      .row
        .col
          .d-flex.justify-content-end
            AppSaveButton.m-t-30.m-b-30(
              name="confirm"
              :disabled="!hasEditPermission()"
              title="actions.confirm"
              @save="createUserAccount"
            )
</template>

<script>
  // store modules
  import shopMatchingModule from "@/config/store/matching/shop"
  import carClassMatchingModule from "@/config/store/matching/car_class"
  import inventoryGroupMatchingModule from "@/config/store/matching/inventory_group"

  // mixins
  import withValidations from "@/mixins/withValidations"
  import withStoreModule from "@/mixins/withStoreModule"
  import withPermissions from "@/mixins/withPermissions"
  import withScrollTop from "@/mixins/withScrollTop"
  import withPasswordValidationErrors from "@/mixins/withPasswordValidationErrors.js"

  // misc
  import { find, isEmpty, map, filter } from "lodash-es"
  import { appDebounce } from "@/helpers/common"
  import { isSharedInventoryEnabled as isOrganizationWithSharedInventory } from "@/helpers/organization"
  import {
    EMAIL_REGEXP,
    PHONE_NUMBER_REGEXP,
    ACCOUNT_FORBIDDEN_SYMBOLS_REGEXP,
    ALERT_TIMEOUT
  } from "@/config/constants"

  const validationsMixin = withValidations(({ required, minLength }) => ({
    account: {
      first_name: {
        required,
        minLength: minLength(1)
      },
      last_name: {
        required,
        minLength: minLength(1)
      },
      account_id: {
        required,
        minLength: minLength(1)
      },
      password: {
        requireCheck(value) {
          if (this.isNewUser) {
            return !!value
          } else {
            return true
          }
        },
        minLength: minLength(8),
        sameAs: (value, self) => value === self.password_confirmation
      },
      password_confirmation: {
        requireCheck(value) {
          if (this.isNewUser) {
            return !!value
          } else {
            return true
          }
        },
        minLength: minLength(8),
        sameAs: (value, self) => value === self.password
      },
      phone_number: {
        phoneCheck: value => isEmpty(value) || PHONE_NUMBER_REGEXP.test(value)
      },
      email: {
        emailCheck: value => isEmpty(value) || EMAIL_REGEXP.test(value)
      },
      role_id: {
        requireCheck(value) {
          return this.account.owner || !!value
        }
      },
      allowed_shop_ids: {
        lengthCheck(value) {
          return this.account.owner || value.length > 0 || this.isOrganizationWithSharedInventory
        }
      },
      allowed_inventory_group_ids: {
        lengthCheck(value) {
          return this.account.owner || value.length > 0 || !this.isOrganizationWithSharedInventory
        }
      },
      allowed_car_class_ids: {
        lengthCheck(value) {
          return this.account.owner || value.length > 0
        }
      }
    }
  }))

  const shopMatchingsMixin = withStoreModule(shopMatchingModule, {
    name: "shopMatching",
    readers: { shops: "items", shopsLoading: "loading" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassMatchingsMixin = withStoreModule(carClassMatchingModule, {
    name: "carClassMatching",
    readers: { carClasses: "items", carClassesLoading: "loading" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const inventoryGroupMatchingsMixin = withStoreModule(inventoryGroupMatchingModule, {
    name: "inventoryGroupMatching",
    readers: { inventoryGroups: "items", inventoryGroupsLoading: "loading" },
    actions: { fetchInventoryGroups: "FETCH_ITEMS" }
  })

  export default {
    props: {
      roles: {
        type: Array,
        default: () => new Array()
      },
      rolesLoading: {
        type: Boolean,
        default: false
      },
      userAccount: {
        type: Object,
        required: true
      },
      resetable: {
        type: Boolean,
        default: true
      },
      saveAction: {
        type: Function,
        required: true
      },
      afterSave: {
        type: Function,
        default: () => new Function()
      },
      accountIdAlreadyExistsRequest: {
        type: Function,
        default: () => new Function()
      },
      formTitle: {
        type: String,
        required: true
      }
    },

    components: {
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppInputPassword: () => import("@/components/elements/AppInputPassword")
    },

    mixins: [
      validationsMixin,
      carClassMatchingsMixin,
      shopMatchingsMixin,
      inventoryGroupMatchingsMixin,
      withPermissions,
      withScrollTop,
      withPasswordValidationErrors
    ],

    data() {
      return {
        accountIdAlreadyExists: null,
        accountIdAlreadyExistsLoading: false,
        isAccountCreatedAlertShown: false,
        selectedShops: [],
        selectedInventoryGroups: [],
        selectedCarClasses: [],
        errors: {},
        account: {
          ...this.userAccount
        }
      }
    },

    created() {
      this.fetchItems().then(() => {
        this.prepareSelectedItems()
      })
    },

    computed: {
      isOrganizationWithSharedInventory,
      loading() {
        return this.rolesLoading || this.shopsLoading || this.accountsLoading || this.inventoryGroupsLoading
      },

      currentRole() {
        return find(this.roles, { id: this.account.role_id })
      },

      isInvalid() {
        return this.isInvalidAccount || (this.isNewUser && this.accountIdAlreadyExists !== false)
      }
    },

    methods: {
      fetchItems: async function() {
        if (isOrganizationWithSharedInventory()) {
          await this.fetchInventoryGroups({ pagination: { _disabled: true } })
        } else {
          await this.fetchShops({ pagination: { _disabled: true } })
        }
        await this.fetchCarClasses({ pagination: { _disabled: true } })
      },

      cleanAccountIdErrors() {
        delete this.errors.account_id
        this.accountIdAlreadyExists = null
      },

      checkAccountIdAlreadyExists(event) {
        if (!this.isNewUser) {
          return
        }

        const accountId = event.target.value
        this.cleanAccountIdErrors()

        this.validateAccountAccountId()

        if (this.isValidAccountAccountId) {
          this.accountIdAlreadyExistsLoading = true
          this.checkAccountIdAlreadyExistsDebounced(accountId)
        }
      },

      checkAccountIdAlreadyExistsDebounced: appDebounce(function(account_id) {
        this.accountIdAlreadyExistsRequest(account_id)
          .then(() => {
            this.errors.account_id = [this.$t("validations.account_id_already_exists")]
            this.accountIdAlreadyExists = true
          })
          .catch(() => {
            this.cleanAccountIdErrors()
            this.accountIdAlreadyExists = false
          })
          .finally(() => (this.accountIdAlreadyExistsLoading = false))
      }),

      getFieldState(name, customOption = undefined) {
        return this.$v.account[name].$error || this.errors[name] || customOption === false ? false : null
      },

      showAccountCreatedAlert() {
        this.isAccountCreatedAlertShown = true
        setTimeout(() => {
          this.isAccountCreatedAlertShown = false
        }, ALERT_TIMEOUT)
      },

      setClearAccountState() {
        this.account = { ...this.userAccount }
        this.selectedShops = []
        this.selectedInventoryGroups = []
        this.selectedCarClasses = []
        this.errors = {}
      },

      selectRole({ id }) {
        this.account.role_id = id
      },

      selectShop(shops) {
        this.selectedShops = shops
        this.account = {
          ...this.account,
          allowed_shop_ids: map(shops, "id")
        }
      },

      selectInventoryGroups(inventoryGroups) {
        this.selectedInventoryGroups = inventoryGroups
        this.account = {
          ...this.account,
          allowed_inventory_group_ids: map(inventoryGroups, "id")
        }
      },

      selectCarClass(carClasses) {
        this.selectedCarClasses = carClasses
        this.account = {
          ...this.account,
          allowed_car_class_ids: map(carClasses, "id")
        }
      },

      prepareSelectedItems() {
        this.selectedShops = filter(this.shops, ({ id }) => this.account.allowed_shop_ids.includes(id))
        this.selectedCarClasses = filter(this.carClasses, ({ id }) => this.account.allowed_car_class_ids.includes(id))
        this.selectedInventoryGroups = filter(this.inventoryGroups, ({ id }) =>
          this.account.allowed_inventory_group_ids.includes(id)
        )
      },

      createUserAccount() {
        this.validateAttributes()

        if (this.isInvalid) {
          this.scrollToInvalidDebounced()
          return
        }

        this.removeEmptyPasswordAttrs()

        this.saveAction(this.account)
          .then(() => {
            if (this.resetable) {
              this.showAccountCreatedAlert()
              this.cancelValidation()
              this.setClearAccountState()
            }

            this.afterSave()
          })
          .catch(({ response }) => {
            this.errors = response.data.record
          })
      },

      removeEmptyPasswordAttrs() {
        const { password, password_confirmation, ...accountData } = this.account

        if (password === "" || password_confirmation === "") {
          this.account = accountData
        }
      },

      handleAccountIdKeyup(event) {
        const { value } = event.currentTarget
        const replacedValue = value.replace(ACCOUNT_FORBIDDEN_SYMBOLS_REGEXP, "")
        this.account.account_id = replacedValue
      },

      scrollToInvalidDebounced: appDebounce(function() {
        this.scrollTo({ target: ".invalid-feedback", block: "center" })
      }, 500)
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .create-account
    margin-top: 50px
    color: $default-black

    .col-xxl-6
      @media (min-width: 1599px)
        flex: 0 0 50%
        max-width: 50%

    &.loading
      opacity: 0.7
      cursor: not-allowed

    &-heading
      color: $default-purple
      font-weight: 300
      font-size: 1.4rem
      padding-bottom: 30px
      padding-left: 25px

    &-form
      &-row
        margin: 10px 0
        display: flex
        align-items: center

      &-field
        padding: 0 6px

        .password-errors
          color: $default-red
          font-size: 80%
          margin-top: 0.25rem

        &-roles
          ::v-deep
            .app-select:not(.opened)
              padding-top: 0
              padding-bottom: 0

              .dropdown-toggle
                border-color: $default-purple

        input
          border-color: $default-purple

          &.is-invalid
            border-color: $default-red

    &-items
      margin-top: 50px

      &-title
        font-size: 1.4rem
        font-weight: 600
        color: $default-purple
        margin-left: 25px
        margin-bottom: 20px

      &-list
        padding: 0 15px 15px 15px

        ::v-deep
          .app-select-items
            max-height: 288px

        &-title
          height: 40px
          min-height: 40px
          margin-left: 10px
          display: flex
          align-items: center

          h6.tip
            margin-left: 10px
            color: $default-gray
            font-size: 0.8rem

        &-select
          margin-top: 15px
          max-height: 400px
          min-height: 400px
          padding: 10px

          &.app-select
            border: 1px solid $default-purple

      &-selected
        &-title
          height: 40px
          min-height: 40px
          margin-left: 10px

        &-list
          margin-top: 15px
          min-height: 400px
          max-height: 400px
          overflow-x: auto
          background: $default-purple-light
          border-radius: 5px
          padding: 15px

          &-item
            padding-bottom: 5px
</style>
