import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import mutationsBuild from "./mutations"
import actionsBuild from "./actions"
import { commonStateBuild, commonGetters } from "../shared"

const BASE_URI = "/car_class_matching"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: true,
  withPagination: true,
  withSorting: true
})

store.mergeState(commonStateBuild())
store.mergeGetters(commonGetters)
store.mergeMutations(mutationsBuild())

const { ADD_ITEM, FETCH_ITEMS, UPDATE_ITEMS, APPLY_SEARCH } = actionsBuild({
  baseURI: BASE_URI
})
store.mergeActions({ ADD_ITEM, APPLY_SEARCH })
store.mergeActions({ FETCH_ITEMS, UPDATE_ITEMS }, withLoading)

export default store
