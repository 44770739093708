import { api } from "@/config"
import { SERIALIZER_VIEW_EXTENDED } from "@/config/constants"
import { map, isEmpty, differenceBy, transform } from "lodash-es"
import { normalizeSortingParams } from "@/helpers/vue-good-table"
import { handleUpdateItemsError } from "@/config/store/matching/shared"

const prepareCarClassesParams = ({ itemsAdded, itemsDeleted, itemsUpdated }) => {
  return transform(
    {
      update: itemsUpdated,
      delete: itemsDeleted,
      create: itemsAdded
    },

    (result, items, key) => {
      if (items.length) {
        result[key] = map(items, ({ id, name, sync, sources_car_classes, dp, inbound }) => ({
          id,
          name,
          sync,
          dp,
          inbound,
          sources_car_class_ids: map(sources_car_classes, "id")
        }))
      }
    },

    {}
  )
}

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state: { itemsDeleted, filters, pagination, sorting } }, customParams = {}) => {
    const { headers, filters: customFilters, ...rest } = customParams

    const params = {
      filters: {
        ...filters,
        ...customFilters
      },
      pagination,
      sorting: normalizeSortingParams(sorting),
      ...rest
    }

    const response = await api.get(baseURI, { params, headers })
    const { items, meta } = response.data.data
    const itemsToSet = differenceBy(items, itemsDeleted, "id")

    commit("SET_ITEMS", itemsToSet)
    commit("SET_PAGINATION_DATA", meta.pagination)
  },

  ADD_ITEM({ commit }) {
    const carClass = { name: "", sources_car_classes: [], immutable_ota_ids: [], sync: false, dp: false }
    commit("ADD_ITEM", { item: carClass, prepend: true })
  },

  UPDATE_ITEMS: async (
    { state: { itemsAdded, itemsDeleted, filters, pagination, sorting }, commit, getters: { itemsUpdated } },
    customParams = {}
  ) => {
    const carClasses = prepareCarClassesParams({ itemsAdded, itemsUpdated, itemsDeleted })
    try {
      if (isEmpty(carClasses)) return Promise.resolve()
      const params = { filters, pagination, sorting, ...customParams }

      const response = await api.put(baseURI, { car_classes: carClasses, ...params })
      const { items, meta } = response.data.data

      commit("SET_ITEMS", items)
      commit("SET_PAGINATION_DATA", meta.pagination)
      commit("SET_INVALID_ITEM_IDS", [])
      commit("RESET_ITEMS_DELETED")
      commit("RESET_ITEMS_ADDED")
    } catch (error) {
      handleUpdateItemsError({ error, commit, itemsUpdated, itemsDeleted })
    }
  },

  APPLY_SEARCH({ commit, dispatch, state }, { search_value }) {
    commit("SET_PAGINATION_DATA", { ...state.pagination, current_page: 1 })
    commit("SET_FILTERS", { search_value })
    dispatch("FETCH_ITEMS", { ...state.filters, serializer_view: SERIALIZER_VIEW_EXTENDED })
  },

  CHECK_GETTING_OTA_DATA: async ({ commit }) => {
    const params = { import_type: "car_classes" }
    const { data } = await api.get(`${baseURI}/check_getting_ota_data`, { params })
    commit("SET_GETTING_OTA_DATA_STATUS", data.data)
  }
})
