import { api, i18n } from "@/config"
import { map, isEmpty, sumBy, differenceBy, transform } from "lodash-es"
import { normalizeSortingParams } from "@/helpers/vue-good-table"
import { showToast } from "@/helpers/toasts"
import { SHOPS_MAX_COUNT, SERIALIZER_VIEW_EXTENDED } from "@/config/constants"
import { handleUpdateItemsError } from "@/config/store/matching/shared"

const isTotalCountExceed = (items, itemsDeleted, pagination) => {
  const total = sumBy(items, item => Number(!isEmpty(item._addedAt))) + pagination.total_count - itemsDeleted.length

  return total > SHOPS_MAX_COUNT
}

const prepareShopsParams = ({ itemsAdded, itemsDeleted, itemsUpdated }) => {
  return transform(
    {
      update: itemsUpdated,
      delete: itemsDeleted,
      create: itemsAdded
    },

    (result, items, key) => {
      if (items.length) {
        result[key] = map(items, ({ id, name, sync, dp, inventory_group, sources_shops }) => ({
          id,
          name,
          sync,
          dp,
          inventory_group_id: inventory_group.id || null, // TODO: already saved for shop mode will be proceed
          sources_shop_ids: map(sources_shops, "id")
        }))
      }
    },

    {}
  )
}

const actualizeActiveItemsCount = (commit, { prevSync = false, currentSync = false, diff = 0 }) => {
  let activeItemsCountDiff = diff

  if (prevSync !== currentSync) {
    activeItemsCountDiff = currentSync === true ? 1 : -1
  }

  commit("ADD_ACTIVE_ITEMS_COUNT", activeItemsCountDiff)
}

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state: { itemsDeleted, filters, pagination, sorting } }, customParams = {}) => {
    const { headers } = customParams
    const params = {
      filters,
      pagination,
      sorting: normalizeSortingParams(sorting),
      ...customParams
    }

    const response = await api.get(baseURI, { params, headers })
    const { items, meta } = response.data.data
    const itemsToSet = differenceBy(items, itemsDeleted, "id")

    commit("SET_ITEMS", itemsToSet)
    commit("SET_PAGINATION_DATA", meta.pagination)
    commit("SET_ACTIVE_ITEMS_COUNT", meta.active_items_count)
  },

  ADD_ITEM({ state: { items, itemsDeleted, pagination }, commit }) {
    if (isTotalCountExceed(items, itemsDeleted, pagination)) {
      return showToast({ text: i18n.t("shop_matching.reach_max_limit_error", { num: SHOPS_MAX_COUNT }) })
    }

    const shop = { inventory_group: {}, name: "", sources_shops: [], immutable_ota_ids: [], sync: false, dp: false }
    commit("ADD_ITEM", { item: shop, prepend: true })
  },

  UPDATE_ITEMS: async (
    { state: { itemsAdded, itemsDeleted, filters, pagination, sorting }, commit, getters: { itemsUpdated } },
    customParams = {}
  ) => {
    const shops = prepareShopsParams({ itemsAdded, itemsUpdated, itemsDeleted })
    try {
      if (isEmpty(shops)) return Promise.resolve()
      const params = { filters, pagination, sorting, ...customParams }

      const response = await api.put(baseURI, { shops, ...params })
      const { items, meta } = response.data.data

      commit("SET_ITEMS", items)
      commit("SET_PAGINATION_DATA", meta.pagination)
      commit("SET_INVALID_ITEM_IDS", [])
      commit("RESET_ITEMS_DELETED")
      commit("RESET_ITEMS_ADDED")
    } catch (error) {
      handleUpdateItemsError({ error, itemsUpdated, itemsDeleted, commit })
    }
  },

  UPDATE_ITEM_BY_INDEX({ state: { items }, commit }, { item, index }) {
    const prevSync = items[index].sync
    const currentSync = item.sync

    commit("SET_ITEM_BY_INDEX", { item, index })
    actualizeActiveItemsCount(commit, { prevSync, currentSync })
  },

  UPDATE_ADDED_ITEM_BY_INDEX({ state: { itemsAdded }, commit }, { item, index }) {
    const prevSync = itemsAdded[index].sync
    const currentSync = item.sync

    commit("SET_ADDED_ITEM_BY_INDEX", { item, index })
    actualizeActiveItemsCount(commit, { prevSync, currentSync })
  },

  DELETE_ITEM_BY_INDEX({ state: { items }, commit }, index) {
    const diff = items[index].sync ? -1 : 0

    commit("DELETE_ITEM_BY_INDEX", index)

    if (diff !== 0) {
      actualizeActiveItemsCount(commit, { diff })
    }
  },

  DELETE_ADDED_ITEM_BY_INDEX({ state: { itemsAdded }, commit }, index) {
    const diff = itemsAdded[index].sync ? -1 : 0

    commit("DELETE_ADDED_ITEM_BY_INDEX", index)
    actualizeActiveItemsCount(commit, { diff })
  },

  APPLY_SEARCH({ commit, dispatch, state }, { search_value, search_type = "with_relations" }) {
    commit("SET_PAGINATION_DATA", { ...state.pagination, current_page: 1 })
    commit("SET_FILTERS", { search_value })
    dispatch("FETCH_ITEMS", { serializer_view: SERIALIZER_VIEW_EXTENDED, search_type })
  },

  CHECK_GETTING_OTA_DATA: async ({ commit }) => {
    const params = { import_type: "shops" }
    const { data } = await api.get(`${baseURI}/check_getting_ota_data`, { params })
    commit("SET_GETTING_OTA_DATA_STATUS", data.data)
  }
})
