import { find, filter } from "lodash-es"
import { showToast } from "@/helpers/toasts"

const actualizeItems = ({ newItems, commit, itemsUpdated, itemsDeleted }) => {
  newItems.forEach(item => {
    const updatedItem = find(itemsUpdated, { id: item.id })
    const deletedItem = find(itemsDeleted, { id: item.id })

    if (updatedItem) {
      item.name = updatedItem.name
      item.sync = updatedItem.sync
      if (updatedItem.sources_shops !== undefined) {
        item.inventory_group = updatedItem.inventory_group
        item.sources_shops = updatedItem.sources_shops
      } else if (updatedItem.sources_car_classes !== undefined) {
        item.sources_car_classes = updatedItem.sources_car_classes
      }
      item._updated = true
    } else if (deletedItem) {
      item._deleted = true
      commit("DELETE_ITEM_BY_ID", item.id)
    }
  })
}

export const handleUpdateItemsError = ({ error, commit, itemsUpdated, itemsDeleted }) => {
  const { data } = error.response.data
  const { invalid_ids, errors } = data.meta.errors

  actualizeItems({ newItems: data.items, commit, itemsUpdated, itemsDeleted })

  commit("SET_ITEMS", data.items)
  commit("SET_PAGINATION_DATA", data.meta.pagination)
  commit("SET_INVALID_ITEM_IDS", invalid_ids)
  commit("RESET_ITEMS_DELETED")

  errors.forEach(text => showToast({ text }))
}

export const commonGetters = {
  syncedItems: ({ items }) => filter(items, "sync")
}

export const commonStateBuild = () => ({
  invalidItemIds: []
})
