import StoreItemsModule from "@/config/store/StoreItemsModule"

const BASE_URI = "/inventory_group_matchings"

import extractActions from "./actions"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch", "create", "update", "delete"],
  withFilters: {
    search_value: ""
  },
  withPagination: true,
  withSorting: true
})

store.mergeActions(extractActions({ BASE_URI }))

export default store
