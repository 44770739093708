import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import mutationsBuild from "./mutations"
import actionsBuild from "./actions"
import { commonStateBuild, commonGetters } from "../shared"

const BASE_URI = "/shop_matching"
const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: [],
  withFilters: true,
  withPagination: true,
  withSorting: true
})

store.mergeState({ ...commonStateBuild(), activeItemsCount: 0 })

store.mergeGetters(commonGetters)
store.mergeMutations(mutationsBuild())

const {
  FETCH_ITEMS,
  ADD_ITEM,
  UPDATE_ITEM_BY_INDEX,
  UPDATE_ADDED_ITEM_BY_INDEX,
  UPDATE_ITEMS,
  DELETE_ITEM_BY_INDEX,
  DELETE_ADDED_ITEM_BY_INDEX,
  APPLY_SEARCH
} = actionsBuild({ baseURI: BASE_URI })

store.mergeActions({
  ADD_ITEM,
  UPDATE_ADDED_ITEM_BY_INDEX,
  UPDATE_ITEM_BY_INDEX,
  DELETE_ITEM_BY_INDEX,
  DELETE_ADDED_ITEM_BY_INDEX,
  APPLY_SEARCH
})
store.mergeActions({ FETCH_ITEMS, UPDATE_ITEMS }, withLoading)

export default store
