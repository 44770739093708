// Component must have `account` property in `data`
//   account: {
//     id: ...,
//     account_id: ...,
//     ...
//   }
// and validationMixin with:
//   account: {
//     ...
//     password: {
//       requireCheck: (value) => ...
//       minLength: minLength(8),
//       sameAs: (value, self) => value === self.password_confirmation
//     },
//     password_confirmation: {
//       requireCheck: (value) => ...
//       minLength: minLength(8),
//       sameAs: (value, self) => value === self.password
//     },
//     ...
//   }

export default {
  computed: {
    isNewUser() {
      return this.account.id === undefined
    },

    passwordRequiredError() {
      const { password, password_confirmation } = this.$v.account
      if (!this.isNewUser) return
      if (password.requireCheck && password_confirmation.requireCheck) return

      return this.$t("set_password.validations.password_required")
    },

    minLengthPasswordError() {
      const { password, password_confirmation } = this.$v.account
      if (password.minLength || password_confirmation.minLength) return

      return this.$t("set_password.validations.at_least_8_characters")
    },

    equalConfirmationPasswordError() {
      if (this.$v.account.password.sameAs) return

      return this.$t("set_password.validations.equal_to_confirmation")
    }
  }
}
