// Use:
// import withValidations from "@/mixins/withValidations"
//
// const validationsMixin = withValidations(({ required, minLength }) => ({
//   name: {
//     required,
//     minLength: minLength(2)
//   },
//   age: {
//     required
//   }
// }))

import { validationMixin } from "vuelidate"
import { reduce, get } from "lodash-es"
import * as validators from "vuelidate/lib/validators"
import { pascalCase } from "@/helpers/common"

const recursiveReduce = (iterable, prefix, callback, object = {}, prevLevels = []) => {
  return reduce(
    iterable,
    (_, value, key) => {
      if (typeof value === "function") {
        return
      }
      const fullPath = prevLevels.concat(key)

      recursiveReduce(value, prefix, callback, object, [...fullPath])

      const name = `${prefix}${pascalCase(fullPath.join("_"))}`

      object[name] = function() {
        return callback(get(this.$v, fullPath))
      }

      return object
    },
    {}
  )
}

const withValidations = extractValidations => {
  const validations = extractValidations(validators)

  return {
    validations,

    mixins: [validationMixin],

    computed: {
      ...recursiveReduce(validations, "isValid", $v => !$v.$error),
      ...recursiveReduce(validations, "isInvalid", $v => $v.$error),

      isValid() {
        return !this.$v.$invalid
      }
    },

    methods: {
      ...recursiveReduce(validations, "validate", $v => $v.$touch()),

      validateAttributes() {
        this.$v.$touch()
      },

      cancelValidation() {
        this.$v.$reset()
      }
    }
  }
}

export default withValidations
